body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ra-rich-text-input .ql-editor {
  border: 1px solid rgba(0, 0, 0, 0.42);
}
.ra-rich-text-input .ql-editor::after {
  height: 0px !important;
}

.ra-rich-text-input .ql-editor::before {
  height: 0px !important;
}

.ql-editor.ql-blank::before {
  content: attr(data-placeholder) !important;
  top: 8px;
  left: 8px !important;
}

.ql-editor {
  min-height: 80px;
  word-break: break-all;
  white-space: normal;
}
